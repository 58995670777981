<template>
  <div class="story">
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <Loading
      :loading="loading"
      message="Loading Story"
    />
    <div v-if="story">
      <v-card>
        <v-img
          :src="(story.image) ? story.image : ''"
          height="200px"
          class="px-3"
        >
          <v-row class="mb-4">
            <v-col>
              <div class="headline">
                {{ story.name }}
              </div>
              <div class="title">
                {{ story.num_quests }} Quests
              </div>
            </v-col>
          </v-row>
          <v-row class="align-end pt-5">
            <v-col>
              <div class="title">
                {{ story.points_per_quest }}
              </div>
              <div>
                Points per Quest
              </div>
            </v-col>
            <v-col
              align="right"
              cols="auto"
            >
              <div class="title">
                {{ story.num_likes }}
              </div>
              <div>
                Likes
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-card>

      <v-row>
        <v-col>
          <Share
            :title="story.name"
            class="mr-4"
          />
          <Like
            :object-id="story.id"
            app="quest"
            model="story"
          />
        </v-col>
      </v-row>

      <div class="section">
        <div class="section-title">
          Dispatch
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="story.dispatch" />
        <!--eslint-enable-->
      </div>

      <v-card>
        <v-card-title>Quests</v-card-title>
        <quest-list
          :story-id="story.id"
          :hide-actions="true"
        />
      </v-card>
    </div>
  </div>
</template>

<script>
import Like from '@/components/Like.vue'
import Loading from '@/components/Loading.vue'
import QuestList from '@/components/QuestList.vue'
import Share from '@/components/Share.vue'

export default {
  components: {
    Like,
    Loading,
    QuestList,
    Share,
  },
  data: function() {
    return {
      story: null,
      alerts: [],
      loading: false,
    }
  },
  created: function () {
    this.slug = this.$route.params.slug
    this.getStory()
  },
  methods: {
    getStory() {
      this.loading = true
      return this.$store.dispatch('stories/getAll')
        .finally(() => {
          this.story = this.$store.getters['stories/getBySlug'](this.slug) || null
          this.loading = false
          if (!this.$online && !this.story) {
            this.alerts.push({
              type: 'error',
              message: 'An internet connection is required to load this story.',
            })
          } else if (!this.story) {
            this.alerts.push({
              type: 'error',
              message: 'Failed to load story.',
            })
          }
        })
    },
  },
}
</script>
